import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, navigate } from '@reach/router'
import queryString from 'query-string'

import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase'
import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import TermsAndConditions from '../TermsAndConditions'

export default function Register(course) {
  const { search } = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const { getCoursePath, getCourseOverviewPath } = useCoursesPath()
  const { code } = queryString.parse(search)

  const verifyRegistrationCode = useCallback(async () => {
    try {
      const firestore = await getFirebaseFirestore()
      const doc = await firestore.doc(`courses/${course.slug}`).get()
      const { registrationCode } = doc.data()

      if (registrationCode !== code) {
        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'warning',
            title: 'ไม่อนุญาตให้เข้าถึงหน้านี้',
            message: 'รหัสลงทะเบียนไม่ถูกต้อง',
          },
        })

        navigate(getCoursePath(course.slug))
        return
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [setIsLoading, course.slug])

  const register = useCallback(async () => {
    try {
      const firestore = await getFirebaseFirestore()
      await firestore
        .collection(`users/${user.uid}/registrations`)
        .doc(course.slug)
        .set({
          uid: user.uid,
          couponId: null,
          createdAt: new Date(),
          updatedAt: new Date(),
          success: false,
        })
      await firestore.doc(`users/${user.uid}`).set(
        {
          registrations: {
            [course.slug]: true,
          },
        },
        { merge: true }
      )

      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'success',
          title: 'การลงทะเบียนเสร็จสมบูรณ์',
          message: 'กรุณารอผู้ดูแลระบบตรวจสอบและดำเนินการเพิ่มคอร์สให้กับคุณ',
        },
      })
      navigate(getCourseOverviewPath(course.slug))
    } catch (error) {}
  }, [user, course.slug])

  useEffect(() => {
    verifyRegistrationCode()
  }, [verifyRegistrationCode])

  return isLoading ? (
    <LoadingImage></LoadingImage>
  ) : (
    <TermsAndConditions {...course} onAccept={register}></TermsAndConditions>
  )
}
