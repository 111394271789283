import React from 'react'
import { graphql } from 'gatsby'

import AuthGuard from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import Register from '../../components/courses/enterprise/Register'
import useCoursesPath from '../../hooks/useCoursesPath'

export default function RegisterTemplate({ data: { course } }) {
  const { getCourseRegisterPath } = useCoursesPath()

  return (
    <AuthGuard
      emailMustVerified={true}
      redirectTo={getCourseRegisterPath(course.slug)}
      preserveSearch={true}
    >
      <Register {...course}></Register>
    </AuthGuard>
  )
}

export const pageQuery = graphql`
  query EnterpriseRegisterQuery($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      title
      termsAndConditions
    }
  }
`
